import * as types from "./ActionTypes";
import { signOutUser } from "libs/awsLib";

/**
 * Theme Actions
 */
export function toggleBoxedLayout(isLayoutBoxed) {
  return { type: types.TOGGLE_BOXED_LAYOUT, isLayoutBoxed };
}
export function toggleCollapsedNav(isNavCollapsed) {
  return { type: types.TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}
export function toggleNavBehind(isNavBehind) {
  return { type: types.TOGGLE_NAV_BEHIND, isNavBehind };
}
export function toggleFixedHeader(isFixedHeader) {
  return { type: types.TOGGLE_FIXED_HEADER, isFixedHeader };
}
export function changeSidebarWidth(sidebarWidth) {
  return { type: types.CHANGE_SIDEBAR_WIDTH, sidebarWidth };
}
export function changeColorOption(colorOption) {
  return { type: types.CHANGE_COLOR_OPTION, colorOption };
}
export function changeTheme(themeOption) {
  return { type: types.CHANGE_THEME, theme: themeOption };
}

/**
 * User Auth Actions
 */
export function authenticate(isAuthenticated, user) {
  return { type: types.USER_AUTHENTICATION, isAuthenticated, user };
}
export function authenticating(isAuthenticating) {
  return { type: types.USER_AUTHENTICATING, isAuthenticating };
}
export function logout() {
  signOutUser();
  return { type: types.LOGOUT, isAuthenticated: false };
}
export function storeOrg(org) {
  return { type: types.STORE_ORG, org };
}
export function webPreviewTokenUpdate(token) {
  return { type: types.WEB_PREVIEW_TOKEN, webPreviewToken: token };
}
export function storeUser(user) {
  return { type: types.STORE_USER, user };
}

/*
 * Cases Actions
*/
export function storeCases(cases, total, key, orgID) {
  return {
    type: types.STORE_CASES,
    cases,
    total,
    key,
    orgID,
  };
}

export function refreshCases(cases, key, orgID) {
  return {
    type: types.REFRESH_CASES,
    cases,
    key,
    orgID,
  };
}

export function changeCasesPage(page, orgID) {
  return {
    type: types.PAGE_CHANGE_CASES,
    page,
    orgID,
  };
}

/*
 * Consumer Org Cases Actions
*/
export function storeConsumerOrgCases(cases, key, orgID) {
  return {
    type: types.STORE_CONSUMER_ORG_CASES,
    cases,
    key,
    orgID,
  };
}

export function refreshConsumerOrgCases(cases, key, orgID) {
  return {
    type: types.REFRESH_CONSUMER_ORG_CASES,
    cases,
    key,
    orgID,
  };
}

export function changeConsumerOrgCasesPage(page, orgID) {
  return {
    type: types.PAGE_CHANGE_CONSUMER_ORG_CASES,
    page,
    orgID,
  };
}

/*
 * Audits Actions
*/
export function storeAudits(audits, count, key, orgID) {
  return {
    type: types.STORE_AUDITS,
    audits,
    count,
    key,
    orgID,
  };
}

export function storeOrgsAudits(audits, count, key) {
  return {
    type: types.STORE_ORGS_AUDITS,
    audits,
    count,
    key,
  };
}

export function changeAuditsPage(page, orgID) {
  return {
    type: types.PAGE_CHANGE_AUDITS,
    page,
    orgID,
  };
}

export function changeOrgsAuditsPage(page) {
  return {
    type: types.PAGE_CHANGE_ORGS_AUDITS,
    page,
  };
}

/*
 * Report Actions
*/

// A single report that belongs to a single org
export function storeReport(report, key, orgID) {
  return {
    type: types.STORE_ORG_REPORT,
    report,
    key,
    orgID,
  };
}

// A single report that belongs to all HLX orgs
export function storeOrgsReport(report, key) {
  return {
    type: types.STORE_ORGS_REPORT,
    report,
    key,
  };
}

// A single report that contains stats across all HLX orgs but does not contain org data
export function storeGlobalReport(report, key) {
  return {
    type: types.STORE_GLOBAL_REPORT,
    report,
    key,
  };
}

/**
 * A single report that contains sample kit usage
 * @param  {array} report
 * @param  {string} orgID - the orgID to store this report under. If not provided, it's stored as a global report
 * @param  {string} key - start and end date delimited by colon in format "YYYY-MM-DD:YYYY-MM-DD"
 */
export function storeSampleKitsReport(report, key, orgID) {
  return {
    type: orgID ? types.STORE_SAMPLE_KITS_REPORT : types.STORE_GLOBAL_SAMPLE_KITS_REPORT,
    report,
    orgID,
    key,
  };
}

/**
 * A single report that contains patient statistics under an organization
 * @param  {array} report
 */
export function storeOrgPatientsReport(report, key, orgID) {
  return {
    type: types.STORE_ORG_PATIENTS_REPORT,
    report,
    orgID,
    key
  };
}

export function storeTotalOrgs(total) {
  return {
    type: types.STORE_TOTAL_ORGS,
    total
  };
}

export function storeTotalOrgsWithDevices(total) {
  return {
    type: types.STORE_TOTAL_ORGS_WITH_DEVICES,
    total
  };
}

/*
 * Notification Actions
*/
export function storeNotificationError(message) {
  return {
    type: types.STORE_NOTIFICATION_ERROR,
    message,
  };
}

export function removeNotificationError() {
  return {
    type: types.REMOVE_NOTIFICATION_ERROR
  };
}

export function storeNotificationInfo(message) {
  return {
    type: types.STORE_NOTIFICATION_INFO,
    message,
  };
}

export function removeNotificationInfo() {
  return {
    type: types.REMOVE_NOTIFICATION_INFO
  };
}

/*
 * Patient Actions
*/
export function storePatients(patients, orgIdKey, patientIdKey, orgId) {
  return {
    patients,
    patientIdKey,
    orgIdKey,
    orgId,
    type: orgId ? types.STORE_ORG_PATIENTS : types.STORE_PATIENTS,
  };
}

export function changePatientsPage(page, orgId) {
  return {
    page,
    orgId,
    type: orgId ? types.PAGE_CHANGE_ORG_PATIENTS : types.PAGE_CHANGE_PATIENTS
  };
}

export function removePatient(patientId, orgId) {
  return {
    patientId,
    orgId,
    type: orgId ? types.REMOVE_ORG_PATIENT : types.REMOVE_PATIENT
  };
}

export function replaceSearchedJobs(jobs) {
  return {
    payload: jobs,
    type: types.REPLACE_SEARCHED_JOBS
  };
}

export function updateJobSearchFilters(key, value) {
  return {
    type: types.UPDATE_JOB_SEARCH_FILTERS,
    payload: {
      key, value
    }
  };
}

/**
 * Orgs Actions
 */
export function assumeOrg(org) {
  return {
    type: types.ASSUME_ORG,
    org
  };
}

export function surrenderOrg() {
  return {
    type: types.SURRENDER_ORG
  };
}

/**
 * Datalake Actions
 */
export function updateDatalakeAccessToken(orgId, token) {
  return {
    type: types.UPDATE_DATALAKE_ACCESS_TOKEN,
    orgId,
    token,
  };
}