import { createSelector } from "reselect";
import User from "models/User";
import AccessToken from "models/AccessToken";

const getUser = (state) => state.user.user;

export const userSelector = createSelector(
  getUser,
  (user) => new User(user)
);

const getNavigationLocation = (state) => state && state.router && state.router.location;

export const searchParamsSelector = createSelector(
  getNavigationLocation,
  (location) => {
    if (!location || !location.search) {
      return {
        orgId: null,
        id: null,
      };
    }
    const urlsp = new URLSearchParams(location.search);
    return {
      orgId: urlsp.get("orgId"),
      id: urlsp.get("id"),
    };
  }
);

export const accessControlSelector = createSelector(
  userSelector,
  searchParamsSelector,
  (user, urlsp) => ({
    hidePHI: user.isHLXSupport && urlsp.orgId !== null,
    isHLXSupport: user.isHLXSupport
  })
);

const getAccessTokens = (state) => state.datalake;

export const accessTokenSelector = createSelector(
  getAccessTokens,
  userSelector,
  searchParamsSelector,
  (accessTokens, user, urlsp) => {
    return urlsp.orgId !== null ? (accessTokens[urlsp.orgId] ? new AccessToken(accessTokens[urlsp.orgId]) : null) : (accessTokens[user.orgId] ? new AccessToken(accessTokens[user.orgId]) : null);
  }
);