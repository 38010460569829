import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import settings from "./settings";
import user from "./user";
import cases from "./cases";
import audits from "./audits";
import reports from "./reports";
import notifications from "./notifications";
import patients from "./patients";
import search from "./search";
import assumedOrg from "./assumedOrg";
import datalake from "./datalake";

export const reducers = {
  router: routerReducer,
  settings,
  user,
  cases,
  audits,
  reports,
  notifications,
  patients,
  search,
  assumedOrg,
  datalake
};

export default combineReducers(reducers);
